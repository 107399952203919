import { default as indexUMXjhcUMPmMeta } from "C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/index.vue?macro=true";
import { default as _91id_93bP40QchOk3Meta } from "C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/order/[id].vue?macro=true";
import { default as _91hash_93cyxYNiqG7RMeta } from "C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue?macro=true";
import { default as waitingbE2r38xUZbMeta } from "C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/waiting.vue?macro=true";
import { default as component_45stub0CRYFP6gozMeta } from "C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub0CRYFP6goz } from "C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___en___default",
    path: "/",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___pt-BR",
    path: "/pt-BR",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "index___zh-CN",
    path: "/zh-CN",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/index.vue")
  },
  {
    name: "order-id___en___default",
    path: "/order/:id()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___en",
    path: "/en/order/:id()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___pt-BR",
    path: "/pt-BR/order/:id()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___it",
    path: "/it/order/:id()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "order-id___zh-CN",
    path: "/zh-CN/order/:id()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/order/[id].vue")
  },
  {
    name: "thank-you-hash___en___default",
    path: "/thank-you/:hash()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___en",
    path: "/en/thank-you/:hash()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___pt-BR",
    path: "/pt-BR/thank-you/:hash()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___it",
    path: "/it/thank-you/:hash()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "thank-you-hash___zh-CN",
    path: "/zh-CN/thank-you/:hash()",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/thank-you/[hash].vue")
  },
  {
    name: "waiting___en___default",
    path: "/waiting",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___en",
    path: "/en/waiting",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___pt-BR",
    path: "/pt-BR/waiting",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___it",
    path: "/it/waiting",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: "waiting___zh-CN",
    path: "/zh-CN/waiting",
    component: () => import("C:/Dev/Projects/Other Clients/xmondo/a-arte-da-influencia/a-arte-da-influencia-landing-page/pages/waiting.vue")
  },
  {
    name: component_45stub0CRYFP6gozMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub0CRYFP6goz
  },
  {
    name: component_45stub0CRYFP6gozMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stub0CRYFP6goz
  },
  {
    name: component_45stub0CRYFP6gozMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stub0CRYFP6goz
  },
  {
    name: component_45stub0CRYFP6gozMeta?.name,
    path: "/pt-br-sitemap.xml",
    component: component_45stub0CRYFP6goz
  },
  {
    name: component_45stub0CRYFP6gozMeta?.name,
    path: "/it-sitemap.xml",
    component: component_45stub0CRYFP6goz
  },
  {
    name: component_45stub0CRYFP6gozMeta?.name,
    path: "/zh-cn-sitemap.xml",
    component: component_45stub0CRYFP6goz
  }
]